var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"validationRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY'),"label-for":"id-company"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-company","placeholder":_vm.$t('SELECT_COMPANY'),"label":"companyname","clearable":false,"options":_vm.companies},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('SCOPE'),"label-for":"id-scope"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SCOPE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-scope","placeholder":_vm.$t('SELECT_SCOPE'),"clearable":false,"label":"name","options":_vm.scopeOptions},model:{value:(_vm.scope),callback:function ($$v) {_vm.scope=$$v},expression:"scope"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('HEADER'),"label-for":"id-header"}},[_c('validation-provider',{attrs:{"name":_vm.$t('HEADER'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-header","maxlength":"100","placeholder":_vm.$t('HEADER')},model:{value:(_vm.media.header),callback:function ($$v) {_vm.$set(_vm.media, "header", $$v)},expression:"media.header"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('LANGUAGE'),"label-for":"id-language"}},[_c('validation-provider',{attrs:{"name":_vm.$t('LANGUAGE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"id-language","options":_vm.languageOptions,"label":"name","clearable":false},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.media.mediaID===0)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('SELECT_FILE')+ ' (*.mp4,*.mov)',"label-for":"id-url"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SELECT_FILE')+ ' (*.mp4,*.mov)',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('CHOOSE_FILE_OR_DROP'),"drop-placeholder":"Drop file here...","accept":"video/*"},model:{value:(_vm.media.selectedFile),callback:function ($$v) {_vm.$set(_vm.media, "selectedFile", $$v)},expression:"media.selectedFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1281200506)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DESCRIPTION'),"label-for":"id-description"}},[_c('quill-editor',{staticClass:"border-bottom-0",attrs:{"id":"id-description","maxlength":"200","options":_vm.editorOption},model:{value:(_vm.media.description),callback:function ($$v) {_vm.$set(_vm.media, "description", $$v)},expression:"media.description"}}),_c('div',{staticClass:"d-flex justify-content-end border-top-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"})])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"disabled":_vm.enabledSpinner,"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.media.mediaID ? _vm.$t('BUTTON.UPDATE') : _vm.$t('BUTTON.ADD'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.RESET'))+" ")])],1),(_vm.enabledSpinner)?_c('b-col',{attrs:{"md":"3"}},[_c('div',[_c('b-spinner',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"variant":"primary","label":"Small Spinner"}})],1)]):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }